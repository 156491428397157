import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/coffeescript-introduction",
  "date": "2014-10-17",
  "title": "COFFEESCRIPT INTRODUCTION",
  "author": "admin",
  "tags": ["development", "javascript", "coffeescript"],
  "featuredImage": "feature.jpg",
  "excerpt": "CoffeeScript is a language that makes JavaScript easier to write and read. It simplifies JavaScript, making it readable and understandable, as well easier to maintain. CoffeeScript compiles into JavaScript"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`To install CoffeeScript, use the following command in your console.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install -g coffee-script
`}</code></pre>
    <p>{`.. or you can download it from:`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/jashkenas/coffeescript"
      }}>{`https://github.com/jashkenas/coffeescript`}</a></p>
    <h2>{`Variables`}</h2>
    <p>{`In CoffeeScript there are :`}</p>
    <ul>
      <li parentName="ul">{`No variable declarations`}</li>
      <li parentName="ul">{`No semicolons`}</li>
    </ul>
    <p>{`Take a look at the following code snippet:`}</p>
    <p><strong parentName="p">{`CoffeeScript`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`message = "Ready for some Coffee?"
alert(message)
`}</code></pre>
    <p>{`This will compile into the following JavaScript file:`}</p>
    <p><strong parentName="p">{`JavaScript`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var message;
message = "Ready for some Coffee?";
alert(message);
`}</code></pre>
    <p>{`There are 2 ways to create functions in JavaScript:`}</p>
    <ul>
      <li parentName="ul">{`Named Functions`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`function coffee() {
    return confirm("Ready for some Coffee?");
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`Function Expressions`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var coffee = function() {
    return confirm("Ready for some Coffee?");
}
`}</code></pre>
    <p>{`In CoffeeScript, it's written with the following syntax:`}</p>
    <p>{`[code]`}{`
coffee();`}</p>
    <pre><code parentName="pre" {...{}}>{`
But in CoffeeScript, we only use **Function Expressions.**

**CoffeeScript**

`}</code></pre>
    <p>{`coffee = ->
confirm "Ready for some Coffee?"`}</p>
    <pre><code parentName="pre" {...{}}>{`
**JavaScript**

\`\`\`js
var coffee = function() {
    return confirm("Ready for some Coffee?");
}
`}</code></pre>
    <p>{`1 tab or 2 spaces indented
`}<inlineCode parentName="p">{`-> converts to function() {`}</inlineCode>{`
Always has a return value`}</p>
    <h2>{`Returning a String`}</h2>
    <p>{`The following highlighted expressions is similar to:`}</p>
    <pre><code parentName="pre" {...{}}>{`"Your answer is #{answer}"
`}</code></pre>
    <p><strong parentName="p">{`CoffeeScript`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`coffee = ->
    answer = confirm "Ready for some Coffee?"
    "Your answer is " + answer
`}</code></pre>
    <p><strong parentName="p">{`JavaScript`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var coffee;
coffee = function() {
    var answer;
    answer = confirm("Ready for some Coffee?");
    return "Your answer is " + answer;
}
`}</code></pre>
    <h2>{`Function Parameters`}</h2>
    <p>{`With CoffeeScript functions, you should use parenthesis on everything
but the outermost call.`}</p>
    <p><strong parentName="p">{`CoffeeScript`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`coffee = (message) ->
    answer = confirm message
    "Your answer is #{answer}"

alert coffee("Ready for some Coffee?")
`}</code></pre>
    <h2>{`Optinal Parameters`}</h2>
    <p>{`If we want a default message, we can assign it to the argument as below: `}</p>
    <p><strong parentName="p">{`CoffeeScript`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`coffee = (message = "Ready for some Coffee?" ) ->
    answer = confirm message
    "Your answer is #{answer}"
`}</code></pre>
    <p><strong parentName="p">{`JavaScript`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var coffee;
coffee = function(message) {
    var answer;
    if (message == null) {
        message = "Ready for some Coffee?";
    }
    answer = confirm(message);
    return "Your answer is " + answer;
}
`}</code></pre>
    <h2>{`The CoffeeScript Compiler (optional)`}</h2>
    <p>{`You can optionally use a CoffeeScript Compiler to compile your code into JavaScript.`}</p>
    <ul>
      <li parentName="ul">{`Install Node.js`}</li>
      <li parentName="ul">{`Install npm`}</li>
      <li parentName="ul">{`Run `}<inlineCode parentName="li">{`$ npm install -g coffee-script`}</inlineCode></li>
    </ul>
    <p>{`Here are few Command-Line examples, you can use:`}</p>
    <p>{`Creates test.js`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`coffee -c test.coffee
`}</code></pre>
    <p>{`Every time test.coffee is updated re-compile.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`coffee -cw test.coffee
`}</code></pre>
    <p>{`Compile all .coffee files in the src dir into the js dir.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`coffee -c src -o js
`}</code></pre>
    <p>{`Every time a file is updated re-compile.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`coffee -wc src -o js
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      